import React, { useState } from 'react';
import './CategoryItemPage.css';

const pumpsData = {
  domesticPumps: [
    { name: 'Aqua Series', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437216/aqua_aq5idk.jpg' ,ppt: 'https://ksbindia.in/pdf/aquaSeriesBrochure2024.pdf'},
    { name: 'Peristar V', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437228/peristar_sr30wi.png',ppt: 'https://ksbindia.in/pdf/peristarBrochure2024.pdf' },
    { name: 'Agribloc', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437204/agribloc_wiqa4t.jpg',ppt: 'https://ksbindia.in/pdf/agriblocBrochure2024.pdf' },
    { name: 'Peristar ZDX', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437194/peristar-zd_u2nj5d.jpg',ppt: 'https://ksbindia.in/pdf/peristarZDBrochure2024.pdf' },
    { name: 'Perijet and TSJI', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437194/peristar-zd_u2nj5d.jpg',ppt: 'https://ksbindia.in/pdf/perijetBrochure.pdf' },
    { name: 'Peristar GS', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437185/GSpump_w55ipq.jpg',ppt: 'https://ksbindia.in/pdf/peristarGseBrochure.pdf' },
    { name: 'Multboost', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437159/booster_cqmxpk.jpg',ppt: 'https://ksbindia.in/pdf/multiboostBrochure.pdf' },
    { name: 'Opali', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437145/opal_u8rs2k.png',ppt: 'https://ksbindia.in/pdf/opaliBrochure2024.pdf' },
    { name: 'Borewell Submersible', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437133/borewell-submersible-pump_uood8p.jpg',ppt: 'https://ksbindia.in/pdf/BorewellSubmersiblePumpsBrochure.pdf' },
    { name: 'KSTP', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437124/kstp_ijvkgl.jpg',ppt: 'https://ksbindia.in/pdf/KSTPBrochure2024.pdf' },
    { name: 'Ama Proter', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437112/ksb_w8uvuc.jpg',ppt: 'https://ksbindia.in/pdf/AmaPorterBrochure.pdf' },
    { name: 'Ama Drainer', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437101/ama-drainer-pump_f5g5vo.jpg',ppt: 'https://ksbindia.in/pdf/amaDrainerBrochure.pdf' },
    { name: 'KGP Panels', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437090/kgp_gos749.jpg',ppt: 'https://ksbindia.in/pdf/kgpBrochure.pdf' },
    { name: 'Auto Boost', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727437079/autoboost_agk1al.jpg',ppt: 'https://ksbindia.in/pdf/autoboostBrochure.pdf' }
  ],
  agriculturalPumps: [
    { name: 'Monosub R', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727438460/monosub_uf6p10.jpg',ppt: 'https://ksbindia.in/pdf/monosubrsBrochure.pdf' },
    { name: 'SPM Induction Motor', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727438447/spm_mtwnml.jpg',ppt: 'https://ksbindia.in/pdf/SPMMotorsBrochure.pdf' },
    { name: 'DSTART Plus', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727438435/DSTART_Plus_a7py2f.jpg',ppt: 'https://ksbindia.in/pdf/dstart-dstart-plus.pdf' },
    { name: 'SP Norm', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727438393/spnorm_naae7t.jpg',ppt: 'https://ksbindia.in/pdf/spNormBrochure.pdf' },
    { name: 'SP Bloc', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727438427/sp_h8j0mw.jpg',ppt: 'https://ksbindia.in/pdf/spNormBrochure.pdf' },
    { name: 'Monosub R (MR)', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727438366/Monosub_R_MR_xzybun.jpg',ppt: 'https://ksbindia.in/pdf/monosubRMRBrochure.pdf' },
    { name: 'Ultra +', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727438355/Ultra_g07oiy.jpg',ppt: 'https://ksbindia.in/pdf/ultraPlusBrochure.pdf' }
  ],
  grundfosPumps: [
    { name: 'CR', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727439222/cr_phzeni.jpg' },
    { name: 'CRNE', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727439182/crnhs_kfsvmn.jpg' },
    { name: 'CRI', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727439251/cri-vertical-inline-pump_ptotdc.jpg' },
    { name: 'CRN', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727439264/crn_z2tb53.jpg' },
    { name: 'CRE', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727439284/cre_wruhic.jpg' },
    { name: 'CRNE-HS', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727439182/crnhs_kfsvmn.jpg' }
  ],
  siemensProducts: [
    { name: 'Contactor 3TF', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727440225/Contactor_3TF_ji9uva.jpg' },
    { name: 'MPCBs - 3RV', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727440214/MPCBs_-_3RV_rtltcx.jpg' },
    { name: 'MPCBs - 3VU', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727440197/MPCBs_-_3VU_iznvnd.png' },
    { name: 'Contactor Relays 3TH3', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727440178/Contactor_Relays_3TH3_lspnsi.jpg' },
    { name: 'Thermal Overload Relays - 3UA', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727440157/Thermal_Overload_Relays_-_3UA_tmzkoy.jpg' },
    { name: 'Thermal Overload Relays - 3UC', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727440137/Thermal_Overload_Relays_-_3UC_pxrkoo.jpg' },
    { name: 'Indicating Lamps', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727440122/Indicating_Lamps_tdport.jpg' },
    { name: 'Push Buttons', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727440085/Push_Buttons_zs2hbd.jpg' },
    { name: 'Push Button Stations 3SB5', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727440073/Push_Button_Stations_3SB5_rct1xb.jpg' },
    { name: 'Timing and Monitoring Relays - 7PV', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727440061/Timing_and_Monitoring_Relays_-_7PV_l6hqge.jpg' },
    { name: 'Timing and Monitoring Relays - 7UG', image: 'https://res.cloudinary.com/dstg58cmo/image/upload/v1727440050/Timing_and_Monitoring_Relays_-_7UG_xihe19.png' }
  ]
};

const CategoryItemPage = () => {
  const [activeCategory, setActiveCategory] = useState('');

  const openPpt = (pptLink) => {
    window.open(pptLink, '_blank');
  };

  const renderPumps = (category) => {
    return (
      <div className="pumps-list">
        {pumpsData[category].map((pump, index) => (
          <div key={index} className="pump-card">
            <img src={pump.image} alt={pump.name} />
            <h3>{pump.name}</h3>
            <button
              className="open-ppt-button"
              onClick={() => openPpt(pump.ppt)}
            >
              Download Brochure
            </button>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="category-page">
      <h1>Categories</h1>
      <br />

      <button className="category-button" onClick={() => setActiveCategory('domesticPumps')}>
        KSB Pumps
      </button>

      <button className="category-button" onClick={() => setActiveCategory('agriculturalPumps')}>
        Agricultural Pumps
      </button>

      <button className="category-button" onClick={() => setActiveCategory('grundfosPumps')}>
        Grundfos Pumps
      </button>

      <button className="category-button" onClick={() => setActiveCategory('siemensProducts')}>
        Siemens Products
      </button>

      {activeCategory && renderPumps(activeCategory)}
    </div>
  );
};


export default CategoryItemPage;
