import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/Navbar'; 
import Home from './components/home/Home';
import About from './components/about/About';
import Product from './components/product/Product';
import CategoryItemPage from './components/categoryItem/CategoryItemPage';
import Gallery from './components/gallery/Gallery';
import Achievements from './components/achievements/Achievements';
import Principals from './components/principals/Principals';
import Offers from './components/offers/Offers';
import Membership from './components/membership/Membership';
import Footer from './components/footer/Footer';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/product" element={<Product />} />
          <Route path="/categoryItem" element={<CategoryItemPage />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/achievements" element={<Achievements />} />
          <Route path="/principals" element={<Principals />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/membership" element={<Membership />} />

        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
