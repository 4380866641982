import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // State for mobile menu

  // Toggle the menu and add class to push content down
  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle menu visibility
    const homeContainer = document.querySelector('.home-container');
    if (homeContainer) {
      if (!isOpen) {
        homeContainer.classList.add('menu-opened');
      } else {
        homeContainer.classList.remove('menu-opened');
      }
    }
  };

  // Auto-close menu when an item is clicked
  const closeMenuOnItemClick = () => {
    setIsOpen(false);
    const homeContainer = document.querySelector('.home-container');
    if (homeContainer) {
      homeContainer.classList.remove('menu-opened');
    }
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar-left">
          <img
            src="https://res.cloudinary.com/dstg58cmo/image/upload/v1727416730/devlogo_utlnu8.jpg"
            alt="Logo"
            className="navbar-logo"
          />
          <a className="navbar-brand" href="#">
            {/* Dev */}
          </a>
        </div>
        <div className={`navbar-right ${isOpen ? 'open' : ''}`}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/" onClick={closeMenuOnItemClick}>Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about" onClick={closeMenuOnItemClick}>About Us</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/product" onClick={closeMenuOnItemClick}>Products</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/categoryItem" onClick={closeMenuOnItemClick}>Category</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/gallery" onClick={closeMenuOnItemClick}>Gallery</Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/achievements" onClick={closeMenuOnItemClick}>Achievements</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/offers" onClick={closeMenuOnItemClick}>Offers</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact" onClick={closeMenuOnItemClick}>Contact</a>
            </li>
          </ul>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <span className="hamburger-bar"></span>
          <span className="hamburger-bar"></span>
          <span className="hamburger-bar"></span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
